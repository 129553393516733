<template>
  <div class="w-full h-full table-sticky">
    <div v-if="!idReport" class="w-full h-full flex flex-col justify-center items-center">
      <p v-if="loading.getParameters" class="text-xs">Loading...</p>
      <p v-else class="text-xs">Pilih report terlebih dahulu.</p>
    </div>
    <table v-else class="table-fixed border-collapse w-full" :class="{'opacity-50 pointer-events-none': loading.getParameters}">
      <thead>
      <tr class="d-theme-dark-bg">
        <th class="border border-t-0 border-solid d-theme-border-grey-light p-1 text-xs text-center whitespace-no-wrap">Name</th>
        <th class="border border-t-0 border-solid d-theme-border-grey-light p-1 text-xs text-center whitespace-no-wrap">Value</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(param, index) in reportParameters" :key="index">
        <td class="text-xs p-3 border border-solid d-theme-border-grey-light text-left font-medium uppercase">{{ param.title }}</td>
        <td class="text-xs border border-solid rounded-none d-theme-border-grey-light text-left">
          <div class="flex flex-row">
            <div class="flex-1">
              <template v-if="!param.is_linked">
                <vs-input v-if="param.type === 'CHARACTER'" v-model="param.value" @keyup="onInputTextChanged(param.uuid, $event.target.value)" type="text" size="" class="w-full text-sm vs-input-no-border vs-input-no-shdow-focus"/>
                <flat-pickr v-if="param.type === 'DATE'" v-model="param.value" @on-change="onInputDateChanged(param.uuid, $event)" class="w-full flatpickr-input rounded-none text-sm border-0"></flat-pickr>
              </template>
              <template v-else>
                <div class="relative">
                  <div class="w-full text-ellipsis overflow-hidden h-full cursor-pointer">
                    <vs-input :value="param.alias ? param.alias : param.value" type="text" class="w-full cursor-pointer text-sm vs-input-no-border vs-input-no-shdow-focus" readonly/>
                  </div>
                  <div class="absolute bottom-0 left-0 w-full h-full cursor-pointer" @click="showModalLinkedDataBrowser(param)">
                  </div>
                </div>
              </template>
            </div>
            <feather-icon class="flex-none p-1 mr-1 cursor-pointer hover:text-danger" v-if="param.value" @click="clearParameter(param)" icon="XIcon" svgClasses="h-4 w-4"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <!--modals-->
    <LinkedDataBrowser :isActive.sync="modalLinkedDataBrowser.active"
                       :idParameter="modalLinkedDataBrowser.idParameter"
                       :titleParam="modalLinkedDataBrowser.titleParameter"
                       @selected="onSelectedModalLinkedDataBrowser($event)"/>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import reportMixin from '@/utilities/mixins/report-mixin'

export default {
  name: 'Parameters',
  components: {
    LinkedDataBrowser: () => import('@/views/pages/reports/main/parts/LinkedDataBrowser'),
    flatPickr
  },
  props: ['idReport'],
  mixins: [reportMixin],
  data () {
    return {
      modalLinkedDataBrowser: {
        uuid: null,
        idParameter: null,
        titleParameter: null,
        active: false
      },
      loading: {
        getParameters: false
      },
      reportParameters: []
    }
  },
  watch: {
    idReport (id) {
      if (id) {
        this.getParameters()
      }
    },
    reportParameters: {
      deep: true,
      handler (value) {
        this.emitParameters(value)
      }
    }
  },
  methods: {
    getParameters () {
      this.reportParameters = []
      this.loading.getParameters = true

      const params = { id_report: this.idReport }
      ReportRepository.getParameters(params)
        .then(response => {
          this.reportParameters = this.buildReportParameters(_.cloneDeep(response.data.data))
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getParameters = false
        })
    },

    onInputTextChanged: _.debounce(function (paramUuid, newVal) {
      this.onChangeParamValue(paramUuid, newVal, null)
    }, 700),

    onInputDateChanged (paramUuid, selectedDates) {
      if (selectedDates.length > 0) {
        const dateString = moment(selectedDates[0]).format('YYYY-MM-DD')
        this.onChangeParamValue(paramUuid, dateString, null)
      }
    },

    onChangeParamValue (paramUuid, newValue, newValueAlias = null) {
      const paramItem = _.find(this.reportParameters, item => item.uuid === paramUuid)
      paramItem.value = newValue
      paramItem.alias = newValueAlias
      this.updateParameterDefaultValue(paramItem.id, newValue, newValueAlias)
    },

    updateParameterDefaultValue (idQueryParam, newValue, newValueAlias) {
      const params = {
        id_query_param: idQueryParam,
        default_value: newValue,
        default_value_alias: newValueAlias
      }
      ReportRepository.updateParameterDefaultValue(params)
        .then(response => {})
        .catch(error => {
          console.log(error)
        })
    },

    showModalLinkedDataBrowser (param) {
      this.modalLinkedDataBrowser.uuid = param.uuid
      this.modalLinkedDataBrowser.idParameter = param.id
      this.modalLinkedDataBrowser.titleParameter = param.title.toUpperCase()
      this.modalLinkedDataBrowser.active = true
    },

    onSelectedModalLinkedDataBrowser (data) {
      const uuid = this.modalLinkedDataBrowser.uuid
      this.onChangeParamValue(uuid, data.key_field_value, data.alias_field_value)
    },

    clearParameter (param) {
      this.onChangeParamValue(param.uuid, null, null)
    },

    buildReportParameters (data) {
      return _.map(data, item => {
        item.uuid = uuid()
        item.value = item.default_value || null
        item.alias = item.default_value_alias || null
        return item
      })
    },

    emitParameters (parameters) {
      const params = [
        ...this.getInjectedParameters(),
        ...parameters
      ]
      this.$emit('update:parameters', params)
    }
  }
}
</script>

<style scoped>

</style>
