import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  methods: {
    getInjectedParameters () {
      return [
        {
          uuid: uuid(),
          name: 'auth_id',
          value: this.$store.state.auth.user.id,
          alias: 'Auth ID',
          description: 'The user ID of current logged in user.',
          isInjected: true
        }
      ]
    },
    isParameterAutomaticInjected (parameterName) {
      return !!_.find(this.getInjectedParameters(), item => {
        return item.name === parameterName
      })
    },
    filterParametersThatAutomaticInjected (listNameOfParameters) {
      const items = []
      listNameOfParameters.forEach(paramName => {
        if (this.isParameterAutomaticInjected(paramName)) {
          items.push(paramName)
        }
      })
      return items
    },
    filterParametersThatNotAutomaticInjected (listOfParametersName) {
      const items = []
      listOfParametersName.forEach(paramName => {
        if (!this.isParameterAutomaticInjected(paramName)) {
          items.push(paramName)
        }
      })
      return items
    }
  }
}
