<template>
  <div class="h-screen flex flex-col overflow-hidden" :class="{'opacity-50 pointer-events-none': loadingInitData}">
    <div class="flex-none h-16 p-3 flex flex-row justify-between items-center d-theme-dark-bg border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light">
      <div class="flex flex-row items-center space-x-3">
        <feather-icon @click="close" icon="XIcon" class="cursor-pointer" svgClasses="h-6 w-6"/>
        <p>Design Report: <span class="font-bold ml-1">{{ report.description }}</span></p>
      </div>
    </div>
    <div class="flex-none h-12 p-3 d-theme-dark-bg flex flex-row items-center justify-between border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light">
      <div class="flex flex-row space-x-3 items-center">
        <div class="flex items-center space-x-1 cursor-pointer px-1" @click="chooseFile" :class="{'pointer-events-none animate-pulse': uploading}">
          <feather-icon icon="UploadIcon" svgClasses="h-4 w-4"/>
          <span class="whitespace-no-wrap text-xs">{{ uploading ?  'Uploading Template ...' : 'Upload New Template' }}</span>
        </div>
        <div class="flex items-center space-x-1 cursor-pointer px-1" @click="download" :class="{'pointer-events-none opacity-50': !report.template_path}">
          <feather-icon icon="DownloadIcon" svgClasses="h-4 w-4"/>
          <span class="whitespace-no-wrap text-xs">Download Current Template</span>
        </div>
        <div class="flex items-center space-x-1 cursor-pointer px-1" @click="openReportEditor">
          <feather-icon icon="CodeIcon" svgClasses="h-4 w-4"/>
          <span class="whitespace-no-wrap text-xs">Open Report Query Editor</span>
        </div>
      </div>
      <div class="flex flex-row space-x-3 items-center">
        <div v-if="fileHandle && liveReloading" class="flex flex-row items-center space-x-1 animate-pulse">
          <div class="w-2 h-2 bg-success rounded-full"></div>
          <p class="text-sm text-success">Watching File: <span class="font-bold">{{ fileHandle.name }}</span></p>
        </div>
        <div v-if="fileHandle" class="flex flex-row items-center space-x-2">
          <vs-switch color="success" v-model="liveReloading"/>
          <label class="text-sm">Detect Template Changes</label>
        </div>
      </div>
    </div>
    <div class="flex-1 flex flex-row space-x-0" style="background-color: rgba(121,121,121,0.1);">
      <!--parameters section-->
      <div class="flex-none w-2/12 flex flex-col d-theme-dark-bg border border-solid border-t-0 border-b-0 border-l-0 d-theme-border-grey-light">
        <div class="flex-none border-t-0 border border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex flex-row justify-between items-center p-2">
            <p class="text-xs font-semibold">Report Parameters</p>
          </div>
        </div>
        <div class="flex-1 overflow-scroll">
          <Parameters ref="parameters" :idReport="$route.params.idReport" v-on:update:parameters="onParametersUpdated"/>
        </div>
        <div class="flex-none h-10 flex flex-row items-center space-x-1 cursor-pointer bg-primary justify-center border border-solid border-t border-b-0 border-r-0 border-l-0 d-theme-border-grey-light" @click="getTemplateData" :class="{'opacity-50 pointer-events-none': loadingGetTemplateData}">
          <feather-icon icon="PlayIcon" svgClasses="h-5 w-5" class="text-white"/>
          <span class="whitespace-no-wrap text-sm text-white font-medium">{{ loadingGetTemplateData ? 'Running...' : 'Run' }}</span>
        </div>
      </div>

      <!--report data section-->
      <div v-if="!isReportDataSectionHidden" class="flex-1 flex flex-col d-theme-dark-bg border border-solid border-t-0 border-b-0 border-l-0 d-theme-border-grey-light">
        <div class="flex-none border-t-0 border border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex flex-row justify-between items-center p-2">
            <p class="text-xs font-semibold">Report Data Objects</p>
          </div>
        </div>
        <div class="flex-1">
          <AceEditor ref="aceEditor" v-model="templateData" @init="onInitAceEditor" lang="json" theme="dracula" :options="aceEditorOptions" width="100%"></AceEditor>
        </div>
        <div class="flex-none flex flex-row items-center space-x-3 mx-2 d-theme-dark-bg justify-end items-center h-10 border border-solid border-t border-b-0 border-r-0 border-l-0 d-theme-border-grey-light">
          <div class="flex items-center space-x-1 cursor-pointer px-1" @click="beautify">
            <feather-icon icon="ZapIcon" svgClasses="h-4 w-4"/>
            <span class="whitespace-no-wrap text-xs font-semibold">Beautify</span>
          </div>
          <div class="flex items-center space-x-1 cursor-pointer px-1" @click="modifyDataActive = !modifyDataActive">
            <feather-icon :icon="modifyDataActive ? 'XIcon' : 'CodeIcon'" svgClasses="h-4 w-4" :class="{'text-danger': modifyDataActive}"/>
            <span class="whitespace-no-wrap text-xs font-semibold" :class="{'text-danger': modifyDataActive}">
              {{ modifyDataActive ? 'Close Modify Data' : 'Modify Data' }}
            </span>
            <div v-if="reportOld.custom_script" style="width: 6px; height: 6px;" class="rounded-full bg-warning"></div>
          </div>
        </div>
      </div>
      <div @click="isReportDataSectionHidden = !isReportDataSectionHidden" class="flex-none border d-theme-dark-bg border-solid border-t-0 border-b-0 border-l-0 d-theme-border-grey-light cursor-pointer hover-bg-primary-transparent-5">
        <div class="w-full h-full flex flex-col justify-center items-center space-y-1">
          <feather-icon v-if="isReportDataSectionHidden" icon="EyeIcon" svgClasses="h-4 w-4" style="transform: rotate(90deg);"/>
          <feather-icon v-else icon="EyeOffIcon" svgClasses="h-4 w-4"/>
          <p class="text-xs select-none font-semibold text-vertical-lr">{{ isReportDataSectionHidden ? 'Show Data Section' : 'Hide Data Section' }}</p>
        </div>
      </div>

      <!--result section-->
      <div v-if="modifyDataActive" class="flex-1 flex flex-col border border-solid border-t-0 border-b-0 border-l-0 d-theme-border-grey-light">
        <div class="flex-1">
          <AceEditor ref="aceEditorCustomScript" v-model="report.custom_script" @init="onInitAceEditorCustomScript" lang="javascript" theme="dracula" :options="aceEditorOptions" width="100%"></AceEditor>
        </div>
        <div class="flex-none flex flex-row space-x-3 justify-end items-center h-10 px-2 d-theme-dark-bg border border-solid border-t border-b-0 border-r-0 border-l-0 d-theme-border-grey-light">
          <div class="flex-1 flex items-center justify-start space-x-2">
            <div class="flex items-center space-x-1 cursor-pointer px-1 text-white bg-success px-10 py-2 rounded justify-center" @click="updateCustomScript" :class="{'pointer-events-none opacity-50': loadingUpdateCustomScript || report.custom_script === reportOld.custom_script}">
              <feather-icon icon="SaveIcon" svgClasses="h-4 w-4"/>
              <span class="whitespace-no-wrap text-xs font-semibold">{{ loadingUpdateCustomScript ? 'Saving...' : 'Save' }}</span>
            </div>
            <p class="text-xs px-1 font-semibold opacity-75" v-if="report.custom_script !== reportOld.custom_script">STATUS: UNSAVED</p>
          </div>
        </div>
      </div>
      <div v-else class="flex-1 flex flex-col d-theme-dark-bg border border-solid border-t-0 border-b-0 border-r-0 d-theme-border-grey-light">
        <div class="flex-none border-t-0 border border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex flex-row justify-between items-center p-2">
            <p class="text-xs font-semibold">Report Result <span class="font-normal" v-if="loadingPreview">(Loading...)</span></p>
          </div>
        </div>
        <div class="flex-1 flex flex-col items-center justify-center w-full h-full">
          <p v-if="report.id && !report.template_path" class="text-sm text-center opacity-75">No template available for this report.<br>Please upload a new one.</p>
          <object v-if="report.id && report.template_path && generatedReport" :data="generatedReport"  class="w-full h-full"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import Parameters from '@/views/pages/reports/main/parts/Parameters'
import _ from 'lodash'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'ReportDesign',
  components: {
    Parameters,
    AceEditor: require('vue2-ace-editor')
  },
  props: {
    selectable: { default: false, type: Boolean }
  },
  mounted () {
    this.initData()
    this.observeFileChanged()
  },
  watch: {
    templateData (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (this.isJson(newVal)) {
          this.onTemplateDataChanged()
        }
      }
    }
  },
  computed: {
    queryParameters () {
      const parameters = {}
      _.each(this.reportParameters, param => {
        parameters[param.name] = param.value ? (param.value.toString().trim() || null) : null
      })
      return parameters
    }
  },
  data () {
    return {
      isFirstInit: true,
      isReportDataSectionHidden: false,
      liveReloading: true,
      loadingInitData: false,
      loadingPreview: false,
      loadingGetTemplateData: false,
      loadingUpdateCustomScript: false,
      modifyDataActive: false,
      uploading: false,
      reportOld: {},
      report: {},
      reportParameters: [],
      templateData: null,
      templateFile: null,
      fileHandle: null,
      generatedReport: null,
      aceEditor: null,
      aceEditorCustomScript: null,
      aceEditorOptions: {
        showPrintMargin: false,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        highlightActiveLine: true,
        highlightSelectedWord: true
      }
    }
  },
  methods: {
    onParametersUpdated (parameters) {
      this.reportParameters = parameters
      const nonInjectedParams = _.filter(parameters, param => !param.isInjected)
      if (this.isFirstInit && !_.isEmpty(nonInjectedParams)) {
        this.getTemplateData()
        this.isFirstInit = false
      }
    },

    openReportEditor () {
      const routeData = this.$router.resolve({
        name: 'report.edit',
        params: { idReport: this.$route.params.idReport },
        query: { close_type: 'CLOSE_WINDOW' }
      })
      window.open(routeData.href, '_blank')
    },

    async initData () {
      this.loadingInitData = true
      await this.getReportData()
      this.getReportParameters()
      this.loadingInitData = false
    },

    getReportData () {
      const idReport = this.$route.params.idReport
      return ReportRepository.show(idReport)
        .then(response => {
          this.report = response.data.data.report
          this.reportOld = _.cloneDeep(this.report)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    getReportParameters () {
      this.$refs.parameters.getParameters()
    },

    getTemplateData () {
      this.loadingGetTemplateData = true

      const reportId = this.report.id
      const params = { params: this.queryParameters }
      return ReportRepository.templateData(reportId, params)
        .then(response => {
          this.templateData = JSON.stringify(response.data, null, '\t')
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
            this.notifyErrorUsingDialog(`Error ${error.response.data.statusCode}: ${error.response.data.message}`)
          } else {
            this.notifyError(error.message)
          }
        })
        .finally(() => {
          this.loadingGetTemplateData = false
        })
    },

    generateReportUsingMockData () {
      this.loadingPreview = true

      const params = {
        id: this.report.id,
        type: 'document',
        convertTo: 'pdf',
        mockData: JSON.stringify(this.templateData)
      }
      ReportRepository.generateReportUsingMockData(params)
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          this.generatedReport = URL.createObjectURL(blob)
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
            this.notifyErrorUsingDialog(`Error ${error.response.data.statusCode}: ${error.response.data.message}`)
          } else {
            this.notifyError(error.message)
          }
        })
        .finally(() => {
          this.loadingPreview = false
        })
    },

    updateCustomScript () {
      this.loadingUpdateCustomScript = true

      const params = {
        id: this.report.id,
        custom_script: this.report.custom_script
      }
      ReportRepository.updateCustomScript(params)
        .then(response => {
          this.reportOld.custom_script = this.report.custom_script
          this.getTemplateData()
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loadingUpdateCustomScript = false
        })
    },

    onInitAceEditor (editor) {
      this.aceEditor = editor
      require('brace/ext/language_tools')
      require('brace/ext/searchbox')
      require('brace/mode/json')
      require('brace/theme/dracula')
      require('brace/snippets/json')
    },

    onInitAceEditorCustomScript (editor) {
      this.aceEditorCustomScript = editor
      require('brace/ext/language_tools')
      require('brace/ext/searchbox')
      require('brace/mode/javascript')
      require('brace/theme/dracula')
      require('brace/snippets/javascript')
    },

    async chooseFile () {
      const options = {
        excludeAcceptAllOption: true,
        multiple: false,
        startIn: 'downloads',
        types: [
          {
            description: 'Spreadsheet File',
            accept: {
              'text/plain': ['.ods', '.xlsx', '.odt', '.docx']
            }
          }
        ]
      }
      this.fileHandle = (await window.showOpenFilePicker(options))[0]
      this.templateFile = await this.fileHandle.getFile()
      this.upload(this.templateFile)
    },

    async observeFileChanged () {
      setInterval(async () => {
        if (this.liveReloading) {
          const fileHandle = this.fileHandle
          const oldFile = this.templateFile
          if (fileHandle && oldFile) {
            const newFile = await fileHandle.getFile()
            if (newFile.lastModified !== oldFile.lastModified) {
              this.templateFile = newFile
              this.upload(newFile)
            }
          }
        }
      }, 1000)
    },

    upload (file) {
      this.uploading = true

      const params = convertToFormData({ file: file })
      ReportRepository.uploadTemplate(this.report.id, params)
        .then(response => {
          this.report.template_path = response.data.data.path
          if (this.isJson(this.templateData)) {
            this.generateReportUsingMockData()
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.uploading = false
        })
    },

    download () {
      window.location = ReportRepository.getTemplateDownloadUrl(this.report.id)
    },

    close () {
      this.$router.push({ name: 'report', query: { selected_report_id: this.$route.params.idReport } })
    },

    onTemplateDataChanged: _.debounce(function () {
      if (this.report.template_path) {
        this.generateReportUsingMockData()
      }
    }, 500),

    beautify () {
      if (this.templateData) {
        const json = JSON.parse(this.templateData)
        this.templateData = JSON.stringify(json, null, '\t')
      }
    },

    isJson (str) {
      if (!str) return false
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }
  }
}
</script>
